
  .top_story_title.light {
    width: fit-content;
    font-size: calc(1.275rem + 0.3vw);
    line-height: normal;
    border-bottom: 2px solid   rgba(134, 134, 134, 0.863);
    margin-bottom: 5px;
  }

  .top_story_title.dark {
    width: fit-content;
    font-size: calc(1.275rem + 0.3vw);
    line-height: normal;
    border-bottom: 2px solid  #3C3105;
    margin-bottom: 5px;
  }
 /* styles.css */
.top_stories {
  padding: 20px;
}

  img {
  width: 100%;
  height: auto;
}

.markdown-content h1 {
  font-size: 1.5rem;
  margin: 10px 0;
}

.markdown-content .top_story_info {
  margin-top: 10px;
}

.markdown-content.light .top_story_author.light {
  color: #ACACAC;  
}

.markdown-content.dark .top_story_author.dark {
  color: #000;
}

.markdown-content .top_story_date {
  color: #777;
  margin-left: 10px;
}

.markdown-content a.dark {
  color: #ACACAC;   
  text-decoration: none; 
}
.markdown-content a.light {
  color: #000;   
  text-decoration: none; 
}

.markdown-content a:hover {
  text-decoration: underline; 
}

.story-link.dark {
  color: #ACACAC;   
}

.story-link.light {
  color: #000;   
}