.story_detail {
    max-width: 1140px;
    margin: 0 auto;
    padding: 20px;
   
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  .story_detail img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .story_detail h1 {
    text-align: center;
    font-size: 2em;
    margin-bottom: 10px;
    color: #333;
  }
  
  .top_story_info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    color: #666;
  }
  
  .top_story_author, .top_story_date {
    font-size: 0.9em;
  }
  
  .markdown-content {
    line-height: 1.6;
    font-size: 1.1em;
    color: #333;
  }
  
  .markdown-content h2, .markdown-content h3, .markdown-content h4, .markdown-content h5, .markdown-content h6 {
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    color: #444;
  }
  
  .markdown-content p {
    margin-bottom: 1em;
  }
  
  .markdown-content a {
    color: #1e90ff;
    text-decoration: underline;
  }
  
  .markdown-content img {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    margin: 20px 0;
  }
  
  .markdown-content blockquote {
    margin: 20px 0;
    padding: 10px 20px;
    background-color: #f9f9f9;
    border-left: 5px solid #ccc;
  }
  
  .markdown-content ul, .markdown-content ol {
    margin: 20px 0;
    padding-left: 40px;
  }
  
  .markdown-content code {
    background-color: #f4f4f4;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: monospace;
  }
  