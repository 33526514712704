.login_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;

}

.title_login {
    font-size: 1.5rem;
     color: #333;
}

.input {
    padding: .75rem 1rem;
    border: none;
    outline: none;
    border: 1px solid #333;
    border-radius: 5px;
}

.btn {
    padding: .5rem  2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}