.subscribe {
    display: flex;
    align-items: center;
    gap: 0.5rem;  
    padding-bottom: 50px;
  }
  
  .subscribe_input {
    padding: 0.8rem 1rem;
    border: 1px solid #ccc;
    border-radius: 25px; /* Makes the input rounded */
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease;
    flex: 1;  
  }
  .subscribe_input::placeholder {
    text-align: center;  

  }
  .subscribe_input:focus {
    border-color: #000000; /* Change border color on focus */
  }
  
  .subscribe_button {
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 25px; /* Makes the button rounded */
    background-color: #000000;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .subscribe_button:disabled {
    background-color: #ccc;  
    cursor: not-allowed;
  }
  
  .subscribe_button:hover {
    background-color: #f0eaeab2;  
    color: #000000;
  }
  .popup {
    text-align: center;
  }
  .popup_btn {
    width: 50%;
    padding: .75rem 1rem;
    border-radius: 25px;
    border: none;
    margin-bottom: 1rem;
    cursor: pointer;
  }

  .popup_btn.light {
    background-color: rgba(134, 134, 134, 0.863);
    color: #000000;
  }

  .popup_btn.dark {
    background-color:#3C3105;
    color: #ACACAC;  
  }