.stories_section,.story_detail_section {
  padding-top: 60px;
}

 
 .stories {
  display: flex;
  column-gap: 1.5rem;
 }

 .top {
  flex: 1;
 }

 @media screen and (max-width: 600px) {
  .stories {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    column-gap: 1.5rem;
   }
 }

 /* Admin */
 .auth_contaner {
  height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;
   place-items: center;
   column-gap: 5rem;
  
 
 }

 .email_sort {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 }

 .title_emails {
  font-size: 2rem;
  color: #333;
  border-bottom: 1px solid #333;
  margin-bottom: 2rem;
 }

 .email_ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: .5rem;
 }

 .email_li {
  font-size: 1rem;
  cursor: pointer;
  color: #333;
 }

 /* Top Stories */
 .top_stories, .featured_stories {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

 }

 .add-users {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
 }


 .story_editor {
  width: 80%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.story_editor h1 {
  text-align: center;
}

.story_editor label {
  display: block;
  margin-bottom: 20px;
  text-align: center;
}

.markdown_content {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.save_btn {
  text-align: center;
}