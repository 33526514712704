* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Light mode */
body.light {
  background-color: #ACACAC;
  color: #000000;
}
 

/* Dark mode */
body.dark {
  background-color: #504107;
  color: #ACACAC;
}

main {
  max-width: 1140px;
  margin: 0 auto;
}