 /* Common styles */
.header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: relative;
    z-index: 999;
  }
  
  .nav {
    width: 100%;
    max-width: 1140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  
  .left, .center, .right {
    display: flex;
    align-items: center;
  }
  
  .left {
    gap: 0.5rem;
  }
  
  .bars {
    color: #f0eaea;
    font-size: 2rem;
    cursor: pointer;
  }
  
  .link {
    font-size: 1.25rem;
    color: #f0eaea;
    font-weight: 600;
    cursor: pointer;
  }
  
  .center {
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .header__logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .logo {
    height: 45px;
    width: 45px;
    border-radius: 100%;
  }
  
  .right {
    gap: 1rem;
  }
  
  .btn_blub {
    background: transparent;
    border: none;
    cursor: pointer;
    border: 1px solid #f0eaea;
    user-select: none;
    font-size: 1rem;
    padding: 0.375rem 0.75rem;
    border-radius: 0.25rem;
    transition: all 0.5s;
  }
  
  .btn_blub:hover {
    background-color: #f0eaeab2;
    border: 1px solid #f0eaeab2;
  }
  
  .icon_blub {
    color: #f0eaea;
  }
  
  .btn_sign {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #f0eaea;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    background-color: transparent;
    border: 1px solid transparent;
    background: #f0eaea6e;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    text-transform: capitalize;
  }
  
  .fa-user {
    margin-right: 0.3rem;
  }
  
  .btn_sign:hover {
    background-color: #f0eaeab2;
    border: 1px solid #f0eaeab2;
    color: #f0eaeadc;
    transition: all 0.5s;
  }
  
  .social_link {
    position: absolute;
    text-align: center;
    top: 70px;
    right: 0;
    left: 0;
    z-index: 50;
    border-bottom: 1px solid black;
    padding-bottom: 0.1rem;
  }
  
  .social_link a {
    cursor: pointer;
  }
  
  /* Light mode styles */
  .header.light {
    background-color: rgba(134, 134, 134, 0.863);
    color: #000000;
  }
  
  .header.light .link,
  .header.light .btn_sign,
  .header.light .btn_blub,
  .header.light .social_link a {
    color: #000000;
  }
  
  /* Dark mode styles */
  .header.dark {
    background-color:#3C3105;
      color: #ACACAC;  
  }
  
  .header.dark .link,
  .header.dark .btn_sign,
  .header.dark .btn_blub,
  .header.dark .social_link a {
    color: #ACACAC;
  }
  
  